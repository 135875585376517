@font-face {
  font-family: 'Corsa Grotesk';
  src: url('CorsaGrotesk-Medium.woff2') format('woff2'),
      url('CorsaGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Corsa Grotesk';
  src: url('CorsaGrotesk-Regular.woff2') format('woff2'),
      url('CorsaGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Corsa Grotesk';
  src: url('CorsaGrotesk-Light.woff2') format('woff2'),
      url('CorsaGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Corsa Grotesk';
  src: url('CorsaGrotesk-Bold.woff2') format('woff2'),
      url('CorsaGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
